import { PageProps } from "../src/types";

import { PageContainer } from "../src/layout/PageContainer";

import * as React from "react";
import type { SpSiteData } from "../src/providers/SiteDataProvider";
import getInitialProps from "../src/util/getInitialProps";

const Page = (props: PageProps) => {
  return <PageContainer {...props} />;
};

Page.getInitialProps = async (context): Promise<PageProps> => {
  const initialProps = await getInitialProps(context);
  if (initialProps.error) {
    context.res.status(500).send(`Error`).end();
  }
  return initialProps;
};
/*
export async function getServerSideProps(context) {
    const params = parseParams(context);

    const slug = params?.slug || 'home';

    const site = getSite(context.req);

    const langShort = params?.langShort || site.defaultLanguage;

    const isEventPage = params?.isEventPage;

    const [siteData, page] = await Promise.all([
        getSiteData(site.baseUrl, site.spName, langShort),
        getPageData(site.baseUrl, site.spName, langShort, slug),
    ]);
    return {
        props: {
            ...site,
            ...siteData,
            page,
            language: langShort,
        },
    };
}
*/
export default Page;
