import React from 'react';
import { useSiteData } from '../providers/SiteDataProvider';

type SocialLinkProps = {
    allLinks: Record<string, string>;
    network: string;
    link?: string;
    iconClass?: string;
};
const SocialLink: React.FC<SocialLinkProps> = ({
    allLinks,
    link,
    iconClass,
    network,
}) => {
    if (!link) link = allLinks[network];

    if (!link || link.length === 0) return null;

    if (!iconClass) iconClass = `icon-${network}`;

    return (
        <li>
            <a
                className={iconClass}
                target="_blank"
                rel="noopener noreferrer"
                href={link}
            >
                <span className="sr-only">{network}</span>
            </a>
        </li>
    );
};

type Props = {
    title: string;
};

export const FooterSocial: React.FC<Props> = ({ title }) => {
    const { social } = useSiteData();
    if (!social) return <div />;

    if (!social || Object.getOwnPropertyNames(social).length === 0)
        return <div />;

    //social['wechat'] = 'https://mp.weixin.qq.com/s/sBcWInFjurpudYclMT0tXQ';

    return (
        <div className="o-footer__social-media h-background h-background--fullwidth">
            <div>
                <h3>{title}</h3>
            </div>
            <div>
                <ul>
                    {Object.keys(social).map((network, idx) => {
                        if (network === 'linkedin')
                            return (
                                <SocialLink
                                    key={idx}
                                    network="linkedin"
                                    iconClass="icon-linkedinoutline"
                                    allLinks={social}
                                />
                            );

                        return (
                            <SocialLink
                                key={idx}
                                network={network}
                                allLinks={social}
                            />
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};
