import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    NextLanguage,
    NextPage,
    SpecialPages,
    SpecialPageType,
    StrapiPageType,
} from '../types';
import { NavigationItem, NavigationItemWithParent } from '../types/navigation';
import { useSiteData } from './SiteDataProvider';
import { BaseComponent } from '../types/components';

type ContextValue = {
    setCurrentItem: (item?: NavigationItemWithParent) => void;
    rootNavItem?: NavigationItemWithParent;
    firstLevelItem?: NavigationItemWithParent;
    navigationTree: NavigationItemWithParent[];
    isInActiveTree: (item: NavigationItemWithParent) => boolean;
};

export const NavigationContext = createContext<ContextValue | null>(null);

const buildTree = (
    navigationItems: NavigationItemWithParent[],
    currentItem?: NavigationItemWithParent,
) => {
    let hierarchy: NavigationItemWithParent[] = currentItem
        ? [currentItem]
        : [];

    let parent = currentItem?.parent;
    while (parent) {
        if (parent) {
            hierarchy = [parent, ...hierarchy];
        }

        parent = parent?.parent;
    }
    console.log(hierarchy);
    return hierarchy;
};

export const NavigationProvider: BaseComponent = ({ children }) => {
    const { navigation, page } = useSiteData();
    const staticRootNavItem = navigation.find(
        (it) => it.id === page.id || (page.parent && it.id === page.parent),
    );
    const [rootNavItem, setRootNavItem] = useState(staticRootNavItem);

    const setCurrentItem = (item?: NavigationItemWithParent) => {
        if (item?.parent) {
            debugger;
        } else if (item) {
            const nextNavItem = navigation.find((it) => it.id === item.id);
            setRootNavItem(nextNavItem);
        }
    };
    const providerValue: ContextValue = {
        rootNavItem,
        isInActiveTree: (item) => {
            if (item.id === 31) debugger;
            return (
                rootNavItem?.id === item.id ||
                (item.parent && rootNavItem?.id === item.parent?.id) ||
                false
            );
        },
        navigationTree: navigation,
        firstLevelItem: undefined,
        setCurrentItem,
    };

    useEffect(() => {
        const nextNavItem = navigation.find((it) => it.id === page.id);
        setCurrentItem(nextNavItem);
    }, [page, navigation]);

    return (
        <NavigationContext.Provider value={providerValue}>
            {useMemo(() => children, [children, page, rootNavItem])}
        </NavigationContext.Provider>
    );
};

export const useNavigation = (): ContextValue => {
    const context = useContext(NavigationContext);

    if (!context) {
        throw new Error('useSiteData must be used within SiteDataProvider');
    }

    return context;
};
