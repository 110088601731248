import React from "react";
import { AnchorList } from "../../components/structure/AnchorList";
import Reference from "../../components/modules/Reference";
import dynamic from "next/dynamic";
import { FrontendPageData } from "@messe-sp/data/types";

const ModuleWrapper = dynamic(
  () => import("../../components/structure/ModuleWrapper")
);
export const PageContent: React.FC<{ page: FrontendPageData }> = ({ page }) => {
  return (
    <div id="content" className="container">
      <div>
        {!page.intro?.trim().length ? null : (
          <div className="richtext abstractComponent">
            <div className="m-rich-text" data-t-name="RichText">
              <p>
                <span
                  className="a-copy-lead"
                  dangerouslySetInnerHTML={{
                    __html: page.intro,
                  }}
                />
              </p>
            </div>
          </div>
        )}
        {page.showAnchors && <AnchorList page={page} />}
        {!page.pageContent
          ? null
          : page.pageContent.map((pageContent) => {
              return (
                <ModuleWrapper key={pageContent.id} pageContent={pageContent} />
              );
            })}
        <Reference />
      </div>
    </div>
  );
};
