import React from 'react';
import { FooterSocial } from '../FooterSocial';
import { useSiteData } from '../../providers/SiteDataProvider';

/*PageProps & {
    language: string;
    baseUrl: string;
};*/

const FooterContact = () => {
    const { getSpecialLink, specialPages, t } = useSiteData();
    const contactLink = getSpecialLink('Contact');
    const contactText = specialPages.Contact?.pageTitle;

    if (!contactLink) return null;

    return (
        <div className="footerHelpteaser abstractComponent">
            <div className="o-footer__service">
                <div
                    className="o-footer__service--left h-background h-background--fullwidth"
                    style={{ width: '100%' }}
                >
                    <h3
                        dangerouslySetInnerHTML={{
                            __html: t('If you need more help, let us know'),
                        }}
                    />
                    <div>
                        <img src="https://salespartner.messefrankfurt.com/content/dam/contact.svg" />

                        <a className="btn btn-default" href={contactLink.href}>
                            {contactText}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
const FooterImprint = () => {
    const { getSpecialLink, specialPages, companyName } = useSiteData();

    const privacyLink = getSpecialLink('Privacy');
    const imprintLink = getSpecialLink('Imprint');

    return (
        <div className="footerBasic abstractComponent">
            <div className="o-footer__basic">
                <ul className="o-footer__legal-standard list-inline">
                    {!imprintLink ? null : <li>{imprintLink.component}</li>}

                    {!privacyLink ? null : <li>{privacyLink.component}</li>}
                </ul>
                {
                    // Contact
                }
                <p className="o-footer__copyright">
                    {companyName ? `© ${companyName}` : null}
                </p>
            </div>
        </div>
    );
};

export const NewsletterFooter: React.FC<{
    title: string;
    link: string;
    linkText: string;
}> = ({ title, link, linkText }) => {
    return (
        <div className="o-footer__newsletter h-background h-background--fullwidth">
            <h3
                dangerouslySetInnerHTML={{
                    __html: title,
                }}
            />

            <a
                target="_blank"
                className="btn btn-default"
                tabIndex={-1}
                href={link}
                dangerouslySetInnerHTML={{
                    __html: linkText,
                }}
                rel="noreferrer"
            />
        </div>
    );
};

export const DefaultLayout = () => {
    const { getSpecialLink, t } = useSiteData();
    const disableNewsletter = false;

    const newsletterLink = disableNewsletter
        ? undefined
        : getSpecialLink('Newsletter');

    return (
        <>
            <footer className="o-footer" data-t-name="Footer" data-t-id="37">
                <div className="container">
                    {newsletterLink && !disableNewsletter ? (
                        <div className="footerNewsletter abstractComponent">
                            <NewsletterFooter
                                link={newsletterLink!.href}
                                title={t('Stay up to date')}
                                linkText={t('Subscribe to our newsletter')}
                            />
                        </div>
                    ) : null}
                    <div className="footerSocial abstractComponent">
                        <FooterSocial title={t('Follow us')} />
                    </div>
                    <FooterContact />
                    <FooterImprint />
                </div>
            </footer>
        </>
    );
};
