import Modal from 'react-modal';
import * as React from 'react';
import { useState } from 'react';
import { useSiteData } from '../../providers/SiteDataProvider';
import styles from './EditToolbar.module.scss';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useAuth } from '../../providers/AuthProvider';

const customStyles = (containerWidth) => {
    return {
        overlay: {
            zIndex: 99999,
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxHeight: '90%',
            width: containerWidth,
        },
    };
};

export const EditToolbar: React.FC<{
    hasChanges: boolean;
    allowPublish: boolean;
    onSave: () => void;
    onPublish: () => void;
}> = ({ hasChanges, onSave, allowPublish, onPublish }) => {
    const { logout } = useAuth();
    const { page, getSpecialLink } = useSiteData();
    const [isOpen, setIsOpen] = useState(false);
    const [containerWidth, setContainerWidth] = useState(100);
    const router = useRouter();

    return (
        <div className={clsx(styles.toolbar)}>
            <div
                className={
                    hasChanges
                        ? clsx('btn', 'btn-primary')
                        : clsx('btn', 'btn-default', styles.btn_disabled)
                }
                onClick={hasChanges ? () => onSave() : undefined}
            >
                Save Changes
            </div>
            <div
                className={
                    allowPublish
                        ? clsx('btn', 'btn-primary')
                        : clsx('btn', 'btn-default', styles.btn_disabled)
                }
                onClick={allowPublish ? () => onPublish() : undefined}
            >
                Publish Changes
            </div>
            <div
                className={clsx('btn', 'btn-primary')}
                onClick={() => {
                    window.location.href = '/page-admin';
                }}
            >
                Site Administration
            </div>
            <div
                className={clsx('btn', 'btn-primary', styles.logout)}
                onClick={() => {
                    logout().then(() => {
                        if (window.location.href.includes('page-admin')) {
                            const homeLink = getSpecialLink('Home');

                            const homeUrl = homeLink?.href || '/';
                            router.push(homeUrl);
                        } else {
                            router.reload();
                        }
                    });
                }}
            >
                Logout
            </div>
            {/*  <Modal
                style={customStyles(containerWidth + 30 + 'px')}
                isOpen={isOpen}
                contentLabel="Example Modal"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => setIsOpen(false)}
            >
                <div className="mo-edit-add-content">
                    <div
                        className="mo-edit-icon mo-edit-icon-cancel"
                        onClick={() => setIsOpen(false)}
                    >
                        d
                    </div>
                    <h2>Add content to page {page.pageTitle}</h2>
                    <div
                        ref={(nodeElement) => {
                            // nodeElement  &&
                            //nodeElement.appendChild(this.getRefContainer());
                        }}
                    />
                </div>
            </Modal>*/}
        </div>
    );
};
