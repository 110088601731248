import React, { useState } from "react";
import Link from "next/link";
import {
  NavigationItem,
  NavigationItemWithParent,
} from "../../types/navigation";
import { useSiteData } from "../../providers/SiteDataProvider";
import { SubNavigation } from "./SubNavigation";
import { useNavigation } from "../../providers/NavigationProvider";
import clsx from "clsx";
import styles from "./NavigationBarItem.module.scss";
type Props = {
  item: NavigationItemWithParent;
  onSubMenuClose?: () => void;
};
export const NavigationBarItem: React.FC<Props> = ({
  item,
  onSubMenuClose,
}) => {
  const { currentLanguage, page } = useSiteData();
  const baseUrl = `/${currentLanguage}`;
  const { isInActiveTree, rootNavItem, setCurrentItem } = useNavigation();
  const active = isInActiveTree(item);

  let link;

  const navigationTitle = item.navigationTitle || item.pageTitle;
  if (item.id !== 3 && item.children && item.children.length > 0) {
    link = (
      <span
        className="o-off-canvas__item-link js-o-off-canvas__item-link o-off-canvas__item-link--has-children"
        data-active={active}
        dangerouslySetInnerHTML={{ __html: navigationTitle }}
        onClick={() => setCurrentItem(item)}
      />
    );
  } else {
    link = (
      <Link href={`${baseUrl}/${item.slug}`}>
        <a
          data-active={active}
          className="o-off-canvas__item-link"
          dangerouslySetInnerHTML={{ __html: navigationTitle }}
        />
      </Link>
    );
  }

  /* FIXME event details*/
  //console.log(item);

  return (
    <li className={clsx("o-off-canvas__item", styles.item)}>
      {link}
      {item.description && (
        <span
          className="o-off-canvas__item-subline"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      )}

      {item.id === 3 || !item.children?.length ? null : (
        <SubNavigation
          selectedItem={rootNavItem}
          hidden={!active}
          baseUrl={baseUrl}
          parent={item}
          language={currentLanguage}
          items={item.children}
          currentPage={page}
          onSubMenuClose={onSubMenuClose}
        />
      )}
    </li>
  );
};
