import Link from 'next/link';
import React, { useState } from 'react';
import { useSiteData } from '../../providers/SiteDataProvider';
import { NavigationBarItem } from './NavigationBarItem';
import {
    NavigationItem,
    NavigationItemWithParent,
} from '../../types/navigation';
import { useNavigation } from '../../providers/NavigationProvider';
//import { PageTypes } from '@messe-sp/data/types';

//  onMenuClose={ this.handleMenuClose } isOpen={ this.state.navOpen } baseUrl={ baseUrl }
type Props = {
    isOpen?: boolean;
    baseUrl: string;

    onMenuClose: () => void;
};

type MainNavigationState = {
    subNaviAllowed: boolean;
    selectedItem?: NavigationItemWithParent;
};
export const MainNavigation: React.FC<Props> = ({ isOpen, onMenuClose }) => {
    const { specialPages, currentLanguage, getSpecialLink } = useSiteData();
    const { rootNavItem, navigationTree } = useNavigation();

    // FIXME reloads
    //console.log('rootNavItem', rootNavItem?.navigationTitle);

    const [state, setState] = useState<MainNavigationState>({
        subNaviAllowed: false,
        selectedItem: undefined,
    });

    const updateState = (nextState: Partial<MainNavigationState>) => {
        setState({
            ...state,
            ...nextState,
        });
    };
    const newsletterLink = getSpecialLink('Newsletter');
    const contactLink = getSpecialLink('Contact');
    const homeLink = getSpecialLink('Home');

    const homeUrl = homeLink?.href || '/';

    let classNames = 'o-main-navigation o-off-canvas o-off-canvas--left';

    if (isOpen) {
        classNames += ' o-off-canvas--open';
        if (rootNavItem?.children?.length && state.subNaviAllowed) {
            classNames += ' subnavi-open ';
        }
    }

    //specialPages.contact.Navigationtitle
    const contactText = specialPages.Contact?.pageTitle || 'CONTACT';

    /* FIXME: Check! */
    /*
    if(currentLanguage && currentLanguage.short !== primaryLanguage.short)
        contactText = specialPages.Contact.translations[currentLanguage.short].Pagetitle;
*/

    const handleNavigationChange = (item: NavigationItemWithParent) => {
        if (item.children && item.children.length > 0) {
            updateState({
                subNaviAllowed: true,
                selectedItem: item,
                //changeFromRouter: false
            });
        } else {
            updateState({
                subNaviAllowed: true,
            });
        }
    };

    /*

                                            onChange={() => handleNavigationChange(page) }
        onSubMenuClose={ onMenuClose }
                                            onSubMenuBack={ () => updateState({subNaviAllowed:false}) }

      <nav  onClick={ ((this.handleOuterClick)) } ref={this.navRef} id="navigation" className={classNames} data-t-name="OffCanvas" data-t-decorator="MainNavigation">
            <div className="o-off-canvas__layer" aria-hidden="true" onClick={ this.handleInnerClick }>
     */

    return (
        <nav
            id="navigation"
            className={classNames}
            data-t-name="OffCanvas"
            data-t-decorator="MainNavigation"
        >
            <div className="o-off-canvas__layer" aria-hidden="true">
                <div className="o-off-canvas__layer-content">
                    <div className="o-off-canvas__layer-scrollable">
                        <button
                            className="o-off-canvas__close o-off-canvas-main__close
                   js-o-off-canvas__close"
                            onClick={onMenuClose}
                        >
                            <span className="sr-only" />
                        </button>

                        <Link href={homeUrl}>
                            <a className="o-off-canvas__home js-o-off-canvas__home text-muted">
                                <span className="icon-home" />
                            </a>
                        </Link>

                        <ul
                            className="o-off-canvas__list
               o-main-navigation__list
               o-main-navigation__list--main"
                        >
                            {!navigationTree
                                ? null
                                : navigationTree.map((page) => {
                                      return (
                                          <NavigationBarItem
                                              key={page.id}
                                              item={page}
                                              onSubMenuClose={onMenuClose}
                                          />
                                      );
                                  })}
                        </ul>
                        {contactLink ? (
                            <Link href={contactLink.href}>
                                <a className="btn btn-primary">{contactText}</a>
                            </Link>
                        ) : null}

                        {!newsletterLink ? null : currentLanguage !== 'en' ? (
                            <a
                                target="_blank"
                                className="btn btn-primary"
                                href={newsletterLink?.href}
                                rel="noreferrer"
                            >
                                Newsletter
                            </a>
                        ) : (
                            <a
                                target="_blank"
                                className="btn btn-primary"
                                href={newsletterLink?.href}
                                rel="noreferrer"
                            >
                                Newsletter
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    );
};
