import React from 'react';
import { useSiteData } from '../../providers/SiteDataProvider';
import styles from './Reference.module.scss';

const Reference: React.FC = () => {
    const { t, navigation, getLocalizedLink, quicklinks } = useSiteData();
    //let exh_page, vis_page, press_page;
    const exh_page = navigation.find(
        (it) => it.id === quicklinks['exhibitors'],
    )?.slug;
    const vis_page = navigation.find(
        (it) => it.id === quicklinks['visitors'],
    )?.slug;
    const press_page = navigation.find(
        (it) => it.id === quicklinks['press'],
    )?.slug;

    /*
    for (const menuItem of navigation) {
        if (menuItem.id === 4) {
            exh_page = menuItem.slug;
        } else if (menuItem.id === 6) {
            vis_page = menuItem.slug;
        } else if (menuItem.id === 8) {
            press_page = menuItem.slug;
        }
    }
*/
    const exh_headline = t('For exhibitors');
    const exh_text = t(
        'Are you an exhibitor and would like to register for one of the upcoming trade fairs?',
    );
    const exh_link = getLocalizedLink(exh_page!);

    const vis_headline = t('For visitors');
    const vis_text = t(
        'Are you a visitor and would like to find out what possibilities you have?',
    );
    const vis_link = getLocalizedLink(vis_page!);

    const press_headline = t('For journalists');
    const press_text = t(
        'Always up to date. Here you can find general information for press at Messe Frankfurt.',
    );
    const press_link = getLocalizedLink(press_page!);

    const read_more_text = t('More information');

    return (
        <div className="reference abstractComponent">
            <div style={{ display: 'inline' }} className="cq-dd-paragraph">
                <div className="teaserconversion_cop teaserConversion abstractComponent">
                    <div
                        data-t-name="ConversionTeaser"
                        className={styles.wrapper}
                        data-t-id="74"
                    >
                        <div className={styles.content}>
                            <a className={styles.item} href={exh_link}>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: exh_text,
                                    }}
                                />
                                <p className={styles.arrow} />
                            </a>

                            <a className={styles.item} href={vis_link}>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: vis_text,
                                    }}
                                />
                                <p className={styles.arrow} />
                            </a>

                            <a className={styles.item} href={press_link}>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: press_text,
                                    }}
                                />
                                <p className={styles.arrow} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reference;
