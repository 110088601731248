import React from 'react';
import { FrontendPageData } from '@messe-sp/data/types';

export const AnchorList: React.FC<{ page: FrontendPageData }> = ({ page }) => {
    const handleClick = (e: React.MouseEvent, targetId: string) => {
        try {
            const target = document.getElementById(`content-${targetId}`);

            const clickedElement = e.target as HTMLLinkElement;
            if (clickedElement.blur) clickedElement.blur();
            if (typeof target?.scrollIntoView === 'function') {
                target.scrollIntoView({
                    behavior: 'smooth',
                });
                e.preventDefault();
            }
        } catch (e) {
            console.error(e);
        }
    };
    return (
        <div className="anchorList abstractComponent">
            <div className="m-anchor-list">
                {page.pageContent
                    ?.filter((content) => content?.headline?.length)
                    .map((content) => {
                        return (
                            <span key={`anchor-${content.id}`}>
                                <a
                                    href={`#content-${content.id}`}
                                    onClick={(e) =>
                                        handleClick(e, content.id.toString())
                                    }
                                    dangerouslySetInnerHTML={{
                                        __html: content.headline!,
                                    }}
                                />
                            </span>
                        );
                    })}
            </div>
        </div>
    );
};
