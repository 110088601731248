import React from "react";

export const ToTop:React.FC<{hidden?:boolean}> = ({hidden}) => {
    return (
        <div className={ hidden ? "a-to-top a-to-top--hidden" :  "a-to-top"} data-t-name="ToTop">
            <div className="container">
                <a className="btn btn-default btn-icon-single" href="#top">
                    <span className="icon-arrowup" />
                </a>
            </div>
        </div>);
}