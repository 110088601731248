import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useSiteData } from './SiteDataProvider';
import { BaseComponent } from '../types/components';
import { FrontendPage } from '../models/FrontendPage';
import { EditToolbar } from '../components/admin/EditToolbar';
import { toast, ToastContainer, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRouter } from 'next/router';
import { useAuth } from './AuthProvider';

export type EditableContentProps<T> = {
    currentValue: T | undefined;
    initialValue: T | undefined;
    update: (value: T | undefined) => void;
    friendlyName: string;
    propName: string;
};

export type EditablePageContextValue = {
    page: FrontendPage;
    lastModification: number;
    isInEditMode: boolean;
    setFocussedComponent: (componentId?: string) => void;
    focussedComponent?: string;
    showModal: (element?: React.ReactElement) => void;
    setError: (error?: Record<string, string>) => void;
    setDirty: () => void;
    reset: () => void;
    bind: <T extends keyof FrontendPage>(
        prop: T,
        friendlyNameOrOptions?: string | unknown,
    ) => EditableContentProps<FrontendPage[T]>;
    showToast: (
        message: string,
        isError?: boolean,
        position?: ToastOptions['position'],
    ) => void;
    isValid: () => boolean;
    setValidationCallback: (cb: (() => boolean) | undefined) => void;
};

export const EditablePageContext =
    createContext<EditablePageContextValue | null>(null);

const createBinding =
    (
        initialPage: FrontendPage,
        currentPage: FrontendPage,
    ): EditablePageContextValue['bind'] =>
    (prop, friendlyNameOrOptions) => {
        let friendlyName = prop as string;
        if (friendlyNameOrOptions) {
            friendlyName =
                typeof friendlyNameOrOptions === 'string'
                    ? friendlyNameOrOptions
                    : prop;
        }
        return {
            currentValue: currentPage[prop],
            initialValue: initialPage[prop],
            update: (value) => {
                (currentPage as Partial<FrontendPage>)[prop] = value;
            },
            propName: prop,
            friendlyName,
        };
    };

let validationCallback: (() => boolean) | undefined = undefined;

export const EditablePageProvider: BaseComponent<{ hideToolbar?: boolean }> = ({
    children,
    hideToolbar,
}) => {
    const { page: initialPage, currentLanguage } = useSiteData();
    const { isAuthenticated } = useAuth();
    const [page, setPage] = useState(initialPage);
    const [lastModification, setLastModification] = useState(Date.now());
    const [isInEditMode, setIsInEditMode] = useState(false);
    const [focussedComponent, setFocussedComponent] = useState<string>();
    const [pendingFocussedComponent, setPendingFocussedComponent] =
        useState<string>();
    const [modal, setModal] = useState<React.ReactElement>();
    const [isDirty, setIsDirty] = useState(false);
    const [error, setError] = useState<Record<string, string>>();
    const [allowPublish, setAllowPublish] = useState(true);

    const router = useRouter();

    const bind = useMemo(() => {
        return createBinding(initialPage, page);
    }, [initialPage, page]);

    useEffect(() => {
        setIsInEditMode(isAuthenticated);
    }, [isAuthenticated]);

    useEffect(() => {
        setPage(initialPage);
    }, [initialPage]);

    useEffect(() => {
        if (typeof window !== 'undefined' && isDirty) {
            const onBeforeUnload = (event: BeforeUnloadEvent) => {
                event.preventDefault();
                return (event.returnValue =
                    'You have unsaved changes, leave anyway?');
            };

            const handleRoute = () => {
                if (typeof 'window' !== 'undefined') {
                    if (
                        !window.confirm(
                            'You have unsaved changes, leave anyway?',
                        )
                    ) {
                        router.events.emit('routeChangeError');
                        throw 'routeChange aborted.';
                    } else {
                        setIsDirty(false);
                    }
                }
            };

            window.addEventListener('beforeunload', onBeforeUnload);

            router.events.on('beforeHistoryChange', handleRoute);

            return () => {
                window.removeEventListener('beforeunload', onBeforeUnload);
                router.events.off('beforeHistoryChange', handleRoute);
            };
        }
    }, [isDirty]);

    const showToast = useCallback(
        (
            message: string,
            isError = false,
            position: ToastOptions['position'] = 'bottom-left',
        ) => {
            const opts: ToastOptions = {
                position,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            };

            if (isError) {
                toast.error(message, opts);
            } else {
                toast.success(message, opts);
            }
        },
        [],
    );

    const handlePublish = useCallback(() => {
        const proceed = confirm(
            'This will publish your content to your publicly visible website. Do you want to proceed?',
        );
        if (proceed) {
            setAllowPublish(false);
            fetch('/export').then((resp) => {
                if (resp.status === 200) {
                    showToast('Website successfully published', false);
                } else {
                    showToast('Something went wrong', true);
                }
                setAllowPublish(true);
            });
        }
    }, []);

    const handleSave = useCallback(() => {
        if (validationCallback && !validationCallback()) {
            showToast(
                'There are some issues with your content, please check invalid values',
                true,
            );
        } else {
            setIsDirty(false);
            fetch('/api/update-page', {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    page,
                    locale: currentLanguage,
                }),
            })
                .then((resp) => {
                    resp?.json().then((val) => {
                        if (val?.error) {
                            showToast(val.error, true);
                            setIsDirty(true);
                        } else {
                            showToast('Saved successfully');
                            setIsDirty(false);
                            setPage({
                                ...page,
                                pageContent: val.value.pageContent,
                            });
                        }
                    });
                })
                .catch((err) => {
                    showToast(err.message, true);
                    setIsDirty(true);
                });
        }
    }, [page, currentLanguage]);

    useEffect(() => {
        if (pendingFocussedComponent) {
            if (error || (validationCallback && !validationCallback())) {
                console.log('HAS ERROR', pendingFocussedComponent);
                showToast(
                    'There are some issues with your content, please check invalid values',
                    true,
                );
                setPendingFocussedComponent(undefined);
            } else {
                console.log('NO ERR', pendingFocussedComponent);
                setFocussedComponent(pendingFocussedComponent);
                setPendingFocussedComponent(undefined);
            }
        }
    }, [error, pendingFocussedComponent]);

    useEffect(() => {
        validationCallback = undefined;
    }, [focussedComponent]);

    const providerValue: EditablePageContextValue = {
        lastModification,
        bind,
        page,
        showToast,
        isInEditMode,
        reset: () => {
            setPendingFocussedComponent(undefined);
            setFocussedComponent(undefined);
        },
        setDirty: () => {
            if (!isDirty) setIsDirty(true);
            setLastModification(Date.now());
        },
        setError,
        showModal: (newModal) => {
            setModal(newModal);
        },
        setFocussedComponent: (componentId) => {
            setPendingFocussedComponent(componentId);
        },
        focussedComponent,
        setValidationCallback: (cb) => {
            validationCallback = cb;
        },
        isValid: () => {
            if (validationCallback) {
                return validationCallback();
            }
            return true;
        },
    };

    return (
        <EditablePageContext.Provider value={providerValue}>
            <ToastContainer toastStyle={{ marginBottom: '100px' }} />
            {modal}
            {children}
            {isAuthenticated && !hideToolbar && (
                <EditToolbar
                    hasChanges={isDirty}
                    onSave={handleSave}
                    allowPublish={allowPublish}
                    onPublish={handlePublish}
                />
            )}
        </EditablePageContext.Provider>
    );
};

export const useEditablePageData = (): EditablePageContextValue => {
    const context = useContext(EditablePageContext);

    if (!context) {
        throw new Error(
            'useEditablePageData must be used within EditablePageDataProvider',
        );
    }

    return context;
};
