import React, { useEffect, useRef, useState } from "react";
import styles from "./StageSmall.module.scss";
import clsx from "clsx";
import { FrontendPage } from "../models/FrontendPage";
import ImageUtil from "../util/ImageUtil";
import { useSiteData } from "../providers/SiteDataProvider";

type Props = {
  page: FrontendPage;
};

//process.env.NEXT_PUBLIC_STRAPI_HOST_COMMON ||
//'https://belgium.salespartner.network';

const getHeaderImage = (page: FrontendPage) => {
  if (page.event?.headerImage) {
    return {
      imageUrl: page.event?.headerImage,
    };
  } else if (page.header) {
    return ImageUtil.getImage(page.header);
  }
  return undefined;
};
export const StageSmall: React.FC<Props> = ({ page }) => {
  const { cubes, currentLanguage } = useSiteData();
  const [image, setImage] = useState<
    | {
        imageUrl?: string;
        imageSet?: string;
      }
    | undefined
  >(() => getHeaderImage(page));

  useEffect(() => {
    if (page.eventId) {
      fetch(`/eventheader/${page.eventId}`)
        .catch((err) => {
          return undefined;
        })
        .then((val) => {
          if (val && val?.status < 400) {
            val.text().then((i) => {
              if (i)
                setImage({
                  imageUrl: i,
                });
            });
          }
        });
    }
  }, [page?.eventId]);

  useEffect(() => {
    const headerImage = getHeaderImage(page);
    if (headerImage?.imageUrl !== image?.imageUrl) setImage(headerImage);
  }, [page]);

  const cssClass = "m-stage--small";

  const showCubes = cubes && page.pageType === "Home";

  if (!image) return null;

  return (
    <div className={`m-stage ${cssClass}`} data-t-name="Stage" data-t-id="10">
      <div
        className={clsx("m-stage-item", styles.stage_item)}
        data-t-name="StageItem"
      >
        <div className={clsx(styles.image)} data-t-name="Image" data-t-id="11">
          <img alt={""} src={image?.imageUrl} srcSet={image?.imageSet} />
        </div>

        {showCubes && cubes.big && (
          <div className={styles.overlay}>
            <div className={styles.cube_wrapper} dir={"ltr"}>
              <div className={styles.cube_big}>
                {cubes.big[currentLanguage]}
              </div>
              {cubes.small && (
                <div className={styles.cube_small}>
                  {cubes.small[currentLanguage]}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
