import { SiteSettings } from "./SiteSettings";
import { NavigationItem } from "./navigation";
import {
  FrontendPageData,
  NextLanguage,
  PageData,
  SiteData,
} from "@messe-sp/data/types";
import { NextPageContent } from "./pages";
import { FrontendPage } from "../models/FrontendPage";

export interface Parent {
  id: number;
  Pagetitle: Title;
  Navigationtitle: Title;
  Slug: Slug;
  Intro: null | string;
  Description: Description;
  Position: number;
  parent: null;
  PageType: StrapiPageType;
  ContentTranslation: StrapiPageContentTranslations;
  Header: ParentHeader;
  HeaderImage: ParentHeaderImage | null;
  UserCreated: boolean | null;
  Deleted: null;
  createdAt: Date;
  updatedAt: Date;
}

export type StrapiPageContentTranslations = Record<
  string,
  StrapiPageContentTranslation
>;

export interface StrapiPageContentTranslation {
  id?: number;
  Pagetitle?: string;
  Navigationtitle?: string;
  Slug?: string;
  Description?: null | string;
  Intro?: string;
}

export interface Header {
  hash?: string;
  ext?: string;
}

export interface StrapiPageHeaderImage extends Header {
  url?: string;
  isShared?: boolean;
}

export type PageContentContentTranslations = Record<
  string,
  PageContentContentTranslation
>;

export interface PageContentContentTranslation {
  headline?: string;
  items?: NlItem[];
  left?: Left;
  right?: Left;
  text?: string;
  button?: string;
  link?: string;
  image?: Image;
  filter?: NlFilter;
  isTeaser?: boolean;
}

export interface NlFilter {
  filterMode: string;
  location?: string;
}

export interface Image {
  hash: string;
  ext: PurpleEXT;
  id?: number | string;
}

export enum PurpleEXT {
  JPEG = ".jpeg",
  Jfif = ".jfif",
  Jpg = ".jpg",
  PNG = ".png",
}

export interface NlItem {
  headline?: string;
  text?: string;
  image?: Image;
  name?: Name;
  title?: string;
  phone?: Phone;
  mail?: Mail;
  download?: Download;
  fax?: string;
}

export interface Download {
  hash: string;
  ext: DownloadEXT;
  id: number;
  size: string;
  name: string;
}

export enum DownloadEXT {
  EXTPDF = ".PDF",
  PDF = ".pdf",
}

export enum Mail {
  KristofNelisBelgiumMessefrankfurtCOM = "kristof.nelis@belgium.messefrankfurt.com",
  SabrinaWynantBelgiumMessefrankfurtCOM = "sabrina.wynant@belgium.messefrankfurt.com",
  WiboBultsmaBelgiumMessefrankfurtCOM = "wibo.bultsma@belgium.messefrankfurt.com",
}

export enum Name {
  KristofNelis = "Kristof Nelis",
  SabrinaWynant = "Sabrina Wynant",
  WiboBultsma = "Wibo Bultsma",
}

export enum Phone {
  The32028809585 = "+32 (0)2 880 95 85",
  The32028809587 = "+32 (0)2 880 95 87",
  The32028809587320491619392 = "+32 (0)2 880 95 87 /// +32 (0)491 61 93 92",
  The32028809588 = "+32 (0)2 880 95 88 ",
  The32028809588320491619391 = "+32 (0)2 880 95 88 /// +32 (0)491 61 93 91",
}

export interface Left {
  link: string;
  headline: string;
  button: string;
  text: string;
  image?: Image;
}

export interface Xx {
  items?: XxItem[];
  headline?: string;
  text?: string;
  image?: Header;
  button?: string;
}

export interface XxItem {
  headline: string;
  text: string;
  image?: Header;
}

export interface Content {
  headline?: string;
  items?: NlItem[];
  left?: Left;
  right?: Left;
  text?: string;
  video?: string;
  id?: string;
  button?: string;
  link?: string;
  image?: Image;
  isTeaser?: boolean;
  maxCount?: number;
  industries?: string[];
  linkFilter?: LinkFilter;
  filter?: ContentFilter;
  de?: De;
}

export interface De {
  text: string;
}

export interface ContentFilter {
  filterMode: string;
  location?: string;
  withOwnContent?: boolean;
  industries?: any[];
  eventIds?: string[];
}

export interface LinkFilter {
  industries: string[];
}

export enum Description {
  Empty = "",
  InformationSurNOSPavillonsBelges = "Information sur nos pavillons Belges",
  TousLesThèmesDeLAposÉvènementEnUnCoupDAposŒil = "Tous les thèmes de l&apos;évènement en un coup d&apos;œil",
}

export interface ParentHeader {}

export interface ParentHeaderImage {
  isShared?: boolean;
}

export enum Title {
  InformationsSurLesSalonsEnFrançais = "Informations sur les salons en français",
  PavillonsBelges = "Pavillons Belges",
  SecteursIndustriels = "Secteurs industriels",
}

export enum Slug {
  InformationsSurLesSalonsEnFrancais = "informations-sur-les-salons-en-francais",
  PavillonsBelges = "pavillons-belges",
  SecteursIndustriels = "secteurs-industriels",
}

export type StrapiLanguage = NextLanguage & {
  createdAt: string;
  updatedAt: string;
};

export type PathParams = {
  languages: string[];
  defaultLang: string;
  spName: string;
  slugs: string[];
};

export type BaseData = {
  isSSG?: boolean;
  primaryLanguage: NextLanguage;
  languages: NextLanguage[];
  pages: FrontendPage[];
  spName: string;
  siteSettings: SiteSettings;
  specialPages: Record<string, SpecialPages>;
  menuByLang: Record<string, NavigationItem[]>;
  eventClusters: string[];
};

export type BaseDataLocalized = Omit<BaseData, "menuByLang" | "pages"> & {
  menu: NavigationItem[];
  language: string;
};

export type BaseDataForPage = BaseDataLocalized & {
  page: FrontendPage;
  isEventPage?: boolean;
};

export type PageProps = SiteData & {
  baseUrl: string;
  apiUrl?: string;
  page: FrontendPageData;
  language: string;
  isProduction?: boolean;
  error?: string;
};

export * from "./modules";
export * from "./strapi";
export * from "./pages";

export type { NextPageContent } from "./pages";
