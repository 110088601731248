import { NextLanguage, NextPage } from '../../types';
import React from 'react';
import Link from 'next/link';
import { NavigationItemWithParent } from '../../types/navigation';

type Props = {
    hidden: boolean;
    language: NextLanguage;
    selectedItem?: NavigationItemWithParent;
    items: Array<NavigationItemWithParent>;
    parent: NavigationItemWithParent;
    baseUrl: string;
    currentPage?: NextPage;
    onSubMenuClose?: () => void;
};
type State = {
    hidden: boolean;
    language: NextLanguage;
    selectedItem?: NavigationItemWithParent;
    item?: NavigationItemWithParent;
    currentPage?: NextPage;
};

export class SubNavigation extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            hidden: props.hidden,
            language: props.language,
            selectedItem: props.selected,
            currentPage: props.currentPage,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            prevState.hidden !== nextProps.hidden ||
            prevState.selected !== nextProps.selected ||
            prevState.currentPage !== nextProps.currentPage
        ) {
            return {
                hidden: nextProps.hidden,
                selected: nextProps.selected,
                currentPage: nextProps.currentPage,
            };
        } else {
            return null;
        }
    }

    render() {
        if (!this.props.items || this.props.items.length === 0) return null;

        let cssClass = 'o-off-canvas__layer';

        const self = this;

        if (!this.state.hidden)
            cssClass += ' o-off-canvas__layer--focus o-off-canvas__layer--open';

        return (
            <div className={cssClass} aria-hidden={this.state.hidden}>
                <div className="o-off-canvas__layer-content">
                    <div className="o-off-canvas__layer-scrollable">
                        <button
                            className="o-off-canvas__close js-o-off-canvas__close"
                            onClick={() => this.props.onSubMenuClose?.()}
                        >
                            <span className="sr-only" />
                        </button>
                        <h2
                            className="o-off-canvas__layer-title js-off-canvas__layer-title"
                            dangerouslySetInnerHTML={{
                                __html:
                                    this.props.parent.navigationTitle ||
                                    this.props.parent.pageTitle,
                            }}
                        />

                        <ul
                            className="o-off-canvas__list
               o-main-navigation__list
               o-main-navigation__list--main"
                        >
                            {this.props.items.map((item) => {
                                if (!item) {
                                    return null;
                                }

                                const isActive =
                                    self.state.currentPage &&
                                    self.state.currentPage.id === item.id;

                                return (
                                    <Link
                                        key={item.id}
                                        href={`${self.props.baseUrl}/${item.slug}`}
                                    >
                                        <a
                                            className="o-off-canvas__item-link"
                                            data-active={isActive}
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    item.navigationTitle ||
                                                    item.pageTitle,
                                            }}
                                        />
                                    </Link>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
