import React from 'react';
import { useSiteData } from '../providers/SiteDataProvider';
import { LangSwitch } from './LangSwitch';
import clsx from 'clsx';
import styles from './ContentHeader.module.scss';
/*
function updatePage(props)
{

    let languageMap = {};
    props.languages.forEach((lang) => {
        languageMap[lang.id] = lang;

        if(props.page && props.page.translations)
        {
            let translation = props.page.translations[lang.id];

            if(translation)
            {
                languageMap[lang.id].pageSlug = translation.Slug;
            } else {
                languageMap[lang.id].pageSlug = '';
            }
        }

    });

    return languageMap;
}*/

type Props = {
    isHidden?: boolean;
    isDocked?: boolean;
    onMenuClick?: () => void;
};

const Logo = () => {
    return (
        <div className={clsx(styles.logo_container)}>
            <div className={clsx(styles.logo_container_logo)}>
                <a
                    target="_blank"
                    href="https://www.messefrankfurt.com/frankfurt/de.html"
                    rel="noreferrer"
                >
                    <img src="https://salespartner.messefrankfurt.com/content/images/messefrankfurt-small.svg" />
                </a>
            </div>
            <div className={clsx(styles.logo_container_wortmarke)}>
                <a
                    target="_blank"
                    href="https://www.messefrankfurt.com/frankfurt/de.html"
                    rel="noreferrer"
                >
                    <img src="https://salespartner.messefrankfurt.com/content/images/wortmarke.svg" />
                </a>
            </div>
        </div>
    );
};

export const ContentHeader: React.FC<Props> = ({
    isDocked,
    isHidden,
    onMenuClick,
}) => {
    const { t } = useSiteData();
    let className = 'o-header o-header--fix';
    if (isHidden) className += ' o-header--fix-hidden';

    if (isDocked) className += ' o-header--fix-docked';

    return (
        <header className={clsx(className, styles.root)} data-t-name="Header">
            <div className="container">
                <div className={clsx('row')}>
                    <div className={styles.wrap}>
                        <div className={clsx(styles.content)}>
                            <div className={clsx(styles.burger)}>
                                <a
                                    className="o-header__main-navigation-toggle js-o-header__main-navigation-toggle"
                                    accessKey="m"
                                    onClick={onMenuClick}
                                >
                                    <span>{t('Menu')}</span>
                                </a>
                            </div>
                            <Logo />
                            <div
                                className={clsx(
                                    'col-xxs-4 o-header__right',
                                    styles.lang_switch,
                                )}
                            >
                                <LangSwitch />
                                <div className="o-header__login">
                                    <div id="infobox" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};
/*
<div className="row">
                        <div className="col-xxs-4 col-xxs-4--displayed-without-content">
                            <a
                                className="o-header__main-navigation-toggle js-o-header__main-navigation-toggle"
                                accessKey="m"
                                onClick={onMenuClick}
                            >
                                <span>{t('Menu')}</span>
                            </a>
                        </div>
                        <Logo />
                        <div className="col-xxs-4 o-header__right">
                            <LangSwitch />
                            <div className="o-header__login">
                                <div id="infobox" />
                            </div>
                        </div>
                    </div>
 */
