import { FrontendImage, FrontendMinimalImage } from '../models/FrontendImage';
import { Merge } from 'type-fest';

export const ImageSizes = [50, 150, 300, 480, 768, 1024, 1280, 1920, 2560];

type Image = Omit<
    Partial<Merge<{ hash: string; ext: string }, FrontendImage>>,
    'id'
>;

//const imagePath = '/content/uploads/';
const imageHost = 'https://salespartner.messefrankfurt.com';
const imagePath = '/content/uploads/';

export const ASSET_PATH = imagePath;

const ImageUtil = {
    getImageFile: (image: Image) => {
        try {
            if (image.hash && image.ext) {
                return image.hash + image.ext;
            } else {
                return undefined;
            }
        } catch (e) {
            console.error(e);
            return undefined;
        }
    },
    getImageUrl: (image: Image, width = 300, relative = false) => {
        if (image.url?.replaceAll(/\s/g, '%20')) {
            return image.url?.replaceAll(/\s/g, '%20');
        } else {
            const imageFile = ImageUtil.getImageFile(image);
            if (imageFile) {
                if (relative) {
                    return `${imageHost}${imagePath}${width}w/${imageFile?.replaceAll(
                        /\s/g,
                        '%20',
                    )}`;
                } else {
                    return `${imageHost}${imagePath}${width}w/${imageFile?.replaceAll(
                        /\s/g,
                        '%20',
                    )}`;
                }
            }
        }

        return undefined;
    },
    getImageSet: (image: Image) => {
        try {
            if (image.sizes) {
                const imageSizes = Array.isArray(image.sizes)
                    ? image.sizes
                    : (Object.values(image.sizes) as FrontendMinimalImage[]);
                const autoSizes = imageSizes
                    .filter((size) => !!size?.url)
                    .map((size) => {
                        return `${
                            size.url.startsWith('http') ? '' : imageHost
                        }${size.url?.replaceAll(/\s/g, '%20')} ${size.width}w`;
                    })
                    .join(', ');

                if (autoSizes?.length) {
                    return autoSizes;
                } else {
                    const baseUrl = `${imageHost}/media/`;
                    return imageSizes
                        .filter((size) => !!size?.filename)
                        .map((size) => {
                            return `${baseUrl}${size.filename} ${size.width}w`;
                        })
                        .join(', ');
                }
            } else {
                const imageFile = ImageUtil.getImageFile(image);

                return ImageSizes.map((size) => {
                    return `${imageHost}${imagePath}${size}w/${imageFile?.replaceAll(
                        /\s/g,
                        '%20',
                    )} ${size}w`;
                }).join(', ');
            }
        } catch (e) {
            console.error(e);
            return undefined;
        }
    },
    getImage: (image: Partial<Image> | undefined, width = 300) => {
        return {
            imageUrl: image ? ImageUtil.getImageUrl(image, width) : undefined,
            imageSet: image ? ImageUtil.getImageSet(image) : undefined,
        };
    },
    getImageCompat: (
        pageContent: {
            oldImage?: Partial<Image>;
            image?: Partial<Image>;
        },
        allowOldImage = false,
    ): Image | undefined => {
        if (pageContent?.image) {
            return pageContent.image;
        }
        return allowOldImage ? pageContent?.oldImage : undefined;
    },
};

export default ImageUtil;
