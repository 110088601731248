import Link from 'next/link';
import { useState } from 'react';
import { useSiteData } from '../providers/SiteDataProvider';
import { useRouter } from 'next/router';
import * as React from 'react';
export const LangSwitch = () => {
    const [isActive, setIsActive] = useState(false);
    const { languages, currentLanguage, getLocalizedLinkForPage, page } =
        useSiteData();
    const router = useRouter();
    if ((languages?.length || 0) < 2) return null;

    const getLink = (newLang: string) => {
        return getLocalizedLinkForPage(page, newLang);
    };

    /*
     <a href={ this.getLink(lang) } disabled={this.state.currentLanguage.short === lang.short } tabIndex={-1}>
                                    {lang.short.toUpperCase()} - {lang.long}
                                </a>
     */

    let cssClasses = 'm-dropdown o-header__language-options';

    if (isActive) {
        cssClasses += ' o-header__language-options--active';
    }
    return (
        <div
            className="o-header__language"
            onClick={() => setIsActive(!isActive)}
        >
            <span className="o-header__language-toggle js-o-header__language-toggle">
                <span>{currentLanguage.toUpperCase()}</span>
            </span>
            <ul className={cssClasses}>
                {languages.map((lang) => {
                    return (
                        <li
                            key={lang.short}
                            className={
                                currentLanguage === lang.short
                                    ? 'm-dropdown-item m-dropdown-item--disabled'
                                    : 'm-dropdown-item'
                            }
                        >
                            <Link
                                href={
                                    currentLanguage === lang.short
                                        ? '#'
                                        : getLink(lang.short)
                                }
                            >
                                <a>
                                    {' '}
                                    {lang.short.toUpperCase()} - {lang.long}
                                </a>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
