import React, { useState } from 'react';
import { useSiteData } from '../providers/SiteDataProvider';
import { NextPage, StrapiPageType } from '../types';
import dynamic from 'next/dynamic';

const isInEditEnvironment = process.env.NODE_ENV !== 'production';

const EditTools = dynamic(
    () => import('../../src/components/admin/ContentEditTools'),
    { ssr: false },
);
const HeadlineEditor = dynamic(
    () => import('../../src/components/admin/HeadlineEditor'),
    { ssr: false },
);

const regex = /<[^>]*>/gm;

type Props = {
    inSettings: boolean;
    onShowSettings: () => void;
    onSettingsCancel: () => void;
    onSettingsSave: () => void;
};

export const PageHeader: React.FC<Props> = ({
    inSettings,
    onSettingsCancel,
    onSettingsSave,
    onShowSettings,
}) => {
    const { primaryLanguage, currentLanguage, page } = useSiteData();
    const isEditingAllowed = false; //Auth.isAuthenticated();
    const [editedValue, setEditedValue] = useState<string>();
    const [editing, setEditing] = useState<boolean>();

    let content;

    if (editing) {
        content = (
            <HeadlineEditor
                onChange={setEditedValue}
                headlineTag="h1"
                headline={page.pageTitle}
            />
        );
    } else {
        content = (
            <h1
                dangerouslySetInnerHTML={{
                    __html: page.pageTitle,
                }}
            />
        );
    }

    const handleEditClick = async () => {
        if (editing) {
            let val = editedValue;
            if (val) {
                val = val.replace(regex, '');

                if (currentLanguage.id === primaryLanguage.id) {
                    /* FIXME: await strapi.updateEntry("pages", page.id, {
                        Pagetitle: val
                    } );*/
                } else {
                    /*               FIXME:     let contentUpdate = page.translations;
                    delete contentUpdate[this.props.primaryLanguage.short];
                    if(!contentUpdate[this.props.language.short])
                        contentUpdate[this.props.language.short] = {};

                    contentUpdate[this.props.language.short].Pagetitle = val;

                    await strapi.updateEntry("pages", this.props.page.id, {
                        ContentTranslation: contentUpdate
                    } );*/
                }

                page.pageTitle = val;
                setEditing(false);

                setTimeout(async () => {
                    // FIXME: await strapi.request("GET", `pages/${this.props.page.id}/rebuild`);
                }, 100);
            } else {
                setEditing(false);
            }
        } else {
            setEditing(true);
        }
    };

    return (
        <div id="heading">
            <div
                className="m-page-heading"
                data-t-name="PageHeading"
                data-t-id="9"
            >
                <div className="container">
                    {!isEditingAllowed
                        ? null
                        : isInEditEnvironment && (
                              <EditTools
                                  editing={editing}
                                  inSettings={inSettings}
                                  showSettings={
                                      page.pageType === StrapiPageType.Normal
                                  }
                                  onCancel={() => setEditing(false)}
                                  onEditClick={handleEditClick}
                                  onSettingsClick={onShowSettings}
                                  onSettingsCancel={onSettingsCancel}
                                  onSettingsSave={onSettingsSave}
                              />
                          )}

                    {content}
                </div>
            </div>
        </div>
    );
};
