import React, { ErrorInfo, ReactElement, useEffect, useState } from "react";
import { BaseDataForPage, PageProps } from "../types";
import { DataBinder } from "../data/DataBinder";
import { StageSmall } from "./StageSmall";
import { SiteDataProvider, useSiteData } from "../providers/SiteDataProvider";
import { ToTop } from "./ToTop";
import { CookieBanner } from "./CookieBanner";
import FontFaceObserver from "fontfaceobserver";
import { PageHeader } from "../layout/PageHeader";
import { MainNavigation } from "../layout/Navigation/MainNavigation";
import { NavigationProvider } from "../providers/NavigationProvider";
import { ContentHeader } from "./ContentHeader";
import { DefaultLayout } from "./Layouts/DefaultLayout";
import { MetaHeader } from "./MetaHeader";
import styles from "./PageContainer.module.scss";
import clsx from "clsx";
import { PageContent } from "./Content/PageContent";
import dynamic from "next/dynamic";

import {
  EditablePageProvider,
  useEditablePageData,
} from "../providers/EditablePageProvider";
import { FrontendPage } from "../models/FrontendPage";
import {
  AuthContextValue,
  AuthProvider,
  useAuth,
} from "../providers/AuthProvider";
import { ConsentProvider } from "../providers/ConsentProvider";

const SideCar = dynamic(() => import("../components/admin/SideCar"));

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _nQc: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ga: any;
    polyfilled: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _hsq: any;
  }
}

type Props = PageProps & {
  children: React.ReactNode;
  onCookiePreferences: (cookiesAllowed: boolean) => void;
  cookiesAllowed: boolean | null;
  authContext: AuthContextValue;
};

type State = {
  page: FrontendPage;
  navOpen: boolean;
  navWidth: number;
  headerHidden: boolean;
  headerDocked: boolean;
  hideToTop: boolean;
  showSettings: boolean;
  dataBinder?: DataBinder;
  showCookieBanner: boolean;
  allowTracking?: boolean;
};
export class PageContainerWithTranslation extends React.Component<
  Omit<Props, "specialPages">,
  State
> {
  private scrollStart = 0;
  private lastY = 0;
  private header?: HTMLElement;
  constructor(props: Props) {
    super(props);

    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this.handleShowSettings = this.handleShowSettings.bind(this);
    this.handleSettingsCancel = this.handleSettingsCancel.bind(this);
    this.handleSettingsSave = this.handleSettingsSave.bind(this);

    this.handleCookieBannerClose = this.handleCookieBannerClose.bind(this);
    this.initTracking = this.initTracking.bind(this);

    this.state = {
      navOpen: false,
      navWidth: 0,
      headerHidden: false,
      headerDocked: true,
      hideToTop: true,
      showSettings: false,
      dataBinder: undefined,
      showCookieBanner: false,
      page: props.page,
    };
  }
  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    if (error.message.indexOf("could not find any data for this route") > -1) {
      alert(
        "It seems that you changed the title of the current page and the link changed. You will be redirected to the start page now."
      );
      window.location.href = "/";
    }
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    debugger;
  }
  initTracking(tracking) {
    const trackingElement = document.getElementById(
      "tracking"
    ) as HTMLScriptElement;
    if (trackingElement) {
      const self = this;
      trackingElement.onload = function () {
        const loc = window.location;

        if (tracking["google"]) {
          window.ga("create", tracking["google"].id, "auto");
        }

        trackPageView(loc, self.state.page);
      };
      if (tracking["hubspot"]) {
        trackingElement.src = `https://js.hs-scripts.com/${tracking["hubspot"].id}.js`;
      } else if (tracking["google"]) {
        trackingElement.src = `https://www.google-analytics.com/analytics.js`;
      } else if (tracking["albacross"]) {
        trackingElement.src = `https://serve.albacross.com/track.js`;
        window._nQc = tracking["albacross"].id;
      }
    }
  }
  componentDidMount() {
    if (!window.polyfilled && typeof document !== "undefined") {
      if (this.props.tracking && this.props.tracking["google"]) {
        if (!window.ga) debugger;

        // TODO: check me
        //window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
      }

      if (this.props.cookiesAllowed) this.initTracking(this.props.tracking);

      window.polyfilled = true;
    }

    if (window) {
      window.addEventListener("scroll", this.handleScroll);
      this.header = document.getElementsByTagName("header")[0];
    }
  }
  componentDidUpdate(prevProps) {
    if (this.state.page.id !== prevProps.page.id) {
      if (this.state.navWidth > 0) this.handleMenuClose();

      window.scrollTo(0, 0);
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const newState: Record<string, unknown> = {};

    if (prevState.cookiesAllowed !== nextProps.cookiesAllowed) {
      newState.showCookieBanner =
        typeof window !== "undefined" && nextProps.cookiesAllowed === null;
    }

    if (prevState.page.id !== nextProps.page.id) {
      newState.showSettings = false;
      newState.page = nextProps.page;
    }

    if (Object.keys(newState).length > 0) {
      return newState;
    } else {
      return null;
    }
  }
  handleShowSettings() {
    this.setState({
      showSettings: true,
    });
  }
  handleSettingsCancel() {
    this.setState({
      showSettings: false,
    });
  }
  handleSettingsSave() {
    const dataBinder = this.state.dataBinder;
    if (dataBinder && dataBinder.save) {
      dataBinder.save().then((res) => {
        this.setState({
          showSettings: false,
        });
      });
    }
  }
  handleScroll(e) {
    const scrollY = e.currentTarget.scrollY;
    const headerY = this.header?.clientHeight || 0;

    if (scrollY === 0) {
      this.setState({
        headerHidden: false,
        headerDocked: true,
        hideToTop: true,
      });

      this.scrollStart = 0;
      this.lastY = 0;
      return;
    }
    if (this.scrollStart > 0) {
      if (this.lastY > 0 && scrollY > this.lastY) {
        this.scrollStart = 0;
        this.lastY = 0;

        this.setState({
          headerHidden: true,
          hideToTop: true,
        });
      } else {
        this.lastY = scrollY;

        if (this.scrollStart - scrollY > 30) {
          this.setState({
            headerHidden: false,
            headerDocked: false,
            hideToTop: false,
          });
        }
      }
    } else {
      this.scrollStart = scrollY;
      if (!this.state.headerHidden && scrollY > headerY) {
        this.setState({
          headerHidden: true,
        });
      }
    }
  }
  updateDimensions() {
    const nav = document.getElementById("navigation")!
      .childNodes[0] as HTMLElement;

    this.setState({
      navWidth: nav.clientWidth,
    });
  }
  handleMenuClose() {
    window.removeEventListener("resize", this.updateDimensions);
    this.setState({
      navOpen: false,
      navWidth: 0,
    });
  }
  handleMenuClick() {
    const nav = document.getElementById("navigation")!
      .childNodes[0] as HTMLElement;

    this.setState({
      navOpen: true,
      navWidth: nav.clientWidth,
    });

    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  handleCookieBannerClose() {
    this.props.onCookiePreferences(true);

    this.initTracking(this.props.tracking);
  }
  render() {
    let stage: ReactElement | null = <StageSmall page={this.state.page} />;
    if (
      !this.state.page.header?.url &&
      !this.state.page.header?.hash &&
      !this.state.page.eventId
    ) {
      stage = null;
    }
    return (
      <React.Fragment>
        <MainNavigation
          onMenuClose={this.handleMenuClose}
          isOpen={this.state.navOpen}
          baseUrl={this.props.baseUrl}
        />

        <main style={{ left: this.state.navWidth + "px" }}>
          <ToTop hidden={this.state.hideToTop} />
          <ContentHeader
            isHidden={this.state.headerHidden}
            isDocked={this.state.headerDocked}
            onMenuClick={this.handleMenuClick}
          />

          {/*<PageLoader />*/}

          <div id="mf-stage" className={clsx("m-stage", styles.stage_wrapper)}>
            {stage}
          </div>

          <PageHeader
            inSettings={this.state.showSettings}
            onShowSettings={this.handleShowSettings}
            onSettingsCancel={this.handleSettingsCancel}
            onSettingsSave={this.handleSettingsSave}
          />
          {this.props.children}

          {this.props.authContext.isAuthenticated && <SideCar />}
          {/*{*/}
          {/*    (!this.state.showSettings) ? this.props.children : <PageSettings onHeaderChanged={ this.handleHeaderChanged } dataBinder={this.state.dataBinder} />*/}
          {/*}*/}
        </main>
        <CookieBanner
          show={this.state.showCookieBanner}
          onClose={this.handleCookieBannerClose}
        />
      </React.Fragment>
    );
  }
}

function trackPageView(loc, page) {
  const trackingElement = document.getElementById(
    "tracking"
  ) as HTMLScriptElement;
  if (trackingElement) {
    if (trackingElement.src.indexOf("hs-scripts.com") > -1) {
      const _hsq = (window._hsq = window._hsq || []);

      _hsq.push(["setPath", loc.pathname]);
      _hsq.push(["trackPageView"]);
    }
    if (window.ga) {
      window.ga("set", {
        page: loc.pathname,
        title: page.Navigationtitle,
      });

      window.ga("send", "pageview");
    }
  }
}

// function usePageViews(page) {
//     let location = useLocation();
//
//
//     React.useEffect(() => {
//         const loc = location;
//
//         trackPageView(loc, page);
//     }, [location]);
// }

const WebfontLoader = () => {
  const iconFont = new FontFaceObserver("mef-iconset-bold");
  const font2 = new FontFaceObserver("Roboto-Regular");
  const font3 = new FontFaceObserver("Roboto-Bold");
  const font4 = new FontFaceObserver("Roboto-Light");

  Promise.all([iconFont.load(), font2.load(), font3.load(), font4.load()]).then(
    function () {
      document.documentElement.classList.remove("fonts-loading");
      document.documentElement.className += " fonts-loaded";
    }
  );

  return null;
};

export const PageContainerProxy: React.FC<Props> = (props) => {
  const siteData = useSiteData();
  const { page } = useEditablePageData();

  const authContext = useAuth();

  const handleCookiePreferences = (preference: boolean) => {
    siteData.setCookiesAccepted(preference);
  };

  return (
    <PageContainerWithTranslation
      siteSettings={props.siteSettings}
      menu={props.menu}
      language={props.language}
      {...siteData}
      page={page}
      authContext={authContext}
      onCookiePreferences={handleCookiePreferences}
    >
      <PageContent page={page} />
      <DefaultLayout />
    </PageContainerWithTranslation>
  );
};

export const PageContainer: React.FC<PageProps> = (props) => {
  //usePageViews(props.page, tracking);

  const currentLanguage = props.site.languages.find(
    (it) => it.short === props.language
  );

  return (
    <AuthProvider>
      <SiteDataProvider {...props}>
        <ConsentProvider>
          <EditablePageProvider>
            <MetaHeader />
            {typeof document !== "undefined" && <WebfontLoader />}
            <NavigationProvider>
              <PageContainerProxy {...props} />
            </NavigationProvider>
          </EditablePageProvider>
        </ConsentProvider>
      </SiteDataProvider>{" "}
    </AuthProvider>
  );
};
